<template>
<div>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col>
        <world-map-simple />
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import WorldMapSimple from '@/components/charts/v2/worldMapSimple.vue'

export default {
  name: 'PoliciesDashboard',
  components: {
    WorldMapSimple
  },
  computed: {
  },
  data () {
    return {
    }
  },
  created: async function () {
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style>
</style>
